import axios from 'axios';
import { useAuthStore } from '@/stores/auth';

export const JwtAxios = {
  get: request('GET'),
  post: request('POST'),
  delete: request('DELETE'),
  put: request('PUT'),
  patch: request('PATCH'),
  download: request('DOWNLOAD'),
  upload: multipartUpload('POST'),
  excelMulitPartGet: multipartRequest('GET'),
  excelMulitPartPost: multipartRequest('POST')
};
let apiURL;
if (['https://vidosw.co.kr', 'http://localhost:80801'].includes(window.location.origin)) {
  apiURL = process.env.VUE_APP_PRODUCT_API_URL;
} else {
  apiURL = process.env.VUE_APP_TEST_API_URL;
}
function request(method) {
  return (path, body) => {
    let url = apiURL + path;
    const requestOptions = {
      headers: authHeader(),
      responseType: 'json'
    };
    if (method == 'GET' && body) {
      url += '?' + jsonToQuery(body);
    } else if (method === 'POST' || method === 'PUT' || method === 'DELETE' || method === 'PATCH') {
      requestOptions.headers['Content-Type'] = 'application/json charset=utf-8';
      requestOptions.body = JSON.stringify(body);
    }
    return axios({
      method,
      url,
      data: body,
      headers: requestOptions.headers,
      responseType: requestOptions.responseType
    })
      .then(handleResponse)
      .catch(handleError);
  };
}
function multipartRequest(method) {
  return (path, fd) => {
    let url = apiURL + path;
    const requestOptions = {
      headers: authHeader()
    };
    requestOptions.headers['accept'] = 'text/plain';
    requestOptions.headers['Content-Type'] = 'application/json';
    // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    return axios({
      method,
      url,
      data: fd,
      headers: requestOptions.headers,
      responseType: 'blob'
    })
      .then(handleResponse)
      .catch(handleError);
  };
}

function multipartUpload(method) {
  return async (path, fd) => {
    let url = apiURL + path;
    return axios({
      method,
      url,
      data: fd,
      headers: {
        ...authHeader(),
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(handleResponse)
      .catch(handleError);
  };
}

function authHeader() {
  let resultModel = {};
  const { accessToken } = useAuthStore();
  if (accessToken != null) {
    resultModel = { Authorization: `Bearer ${accessToken}` };
  }
  return resultModel;
}

function handleResponse(response) {
  const data = response.data;
  if (response.status != 200) {
    const { refreshToken, logout } = useAuthStore();
    if ([401, 403].includes(response.status) && refreshToken) {
      logout();
      return;
    }

    const error = (data && data.message) || response.statusText;
    return Promise.reject(error);
  }

  return data;
}

function handleError(error) {
  let response = error.response;
  if (response == undefined) {
    return false;
  }

  let data = response.data;
  if ([401, 403].includes(response.status)) {
    const { logout } = useAuthStore();
    logout();
    return;
  }

  return data;
}

function jsonToQuery(param) {
  if (typeof param != 'object') {
    return false;
  }

  var str = '';
  for (var key in param) {
    str += `${key}=${param[key]}&`;
  }

  return str;
}
