import { JwtAxios } from '@/packaging';

export const productApi = {
  async SearchProduct(model) {
    const res = await JwtAxios.post(`/Product/SearchProduct`, model);
    return res;
  },
  async RemoveProduct(model) {
    const res = await JwtAxios.delete(`/Product/RemoveProduct`, model);
    return res;
  },
  async GetMemo(id) {
    const res = await JwtAxios.get(`/Product/GetMemo?id=${id}`);
    return res;
  },
  async SetMemo(model) {
    const res = await JwtAxios.patch('/Product/SetMemo', model);
    return res;
  },
  async getProductBasicInfo(id) {
    const res = await JwtAxios.get(`/Product/GetProductBasicInfo?id=${id}`);
    return res;
  },
  async updateProductBasicInfo(body) {
    const res = await JwtAxios.patch(`/Product/UpdateProductBasicInfo`, body);
    return res;
  },
  async getProductMainImages(id) {
    const res = await JwtAxios.get(`/Product/GetProductMainImages?id=${id}`);
    return res;
  },
  async updateProductMainImages(body) {
    const res = await JwtAxios.patch(`/Product/UpdateProductMainImages`, body);
    return res;
  },
  async getProductOptionGroups(id) {
    const res = await JwtAxios.get(`/Product/GetProductOptionGroups?id=${id}`);
    return res;
  },
  async convertDetailOption(id, body) {
    const res = await JwtAxios.post(`/Product/ConvertDetailOption?id=${id}`, body);
    return res;
  },
  async updateProductOption(body) {
    const res = await JwtAxios.patch(`/Product/UpdateProductOption`, body);
    return res;
  },
  async getProductDetailPage(id) {
    const res = await JwtAxios.get(`/Product/GetProductDetailPage?id=${id}`);
    return res;
  },
  async updateProductDetailPage(body) {
    const res = await JwtAxios.patch(`/Product/UpdateProductDetailPage`, body);
    return res;
  },
  async VulkDelete(body) {
    const res = await JwtAxios.delete(`/Product/VulkDelete`, body);
    return res;
  },
  async GetUploadFailLog(body) {
    const res = await JwtAxios.post(`/Product/GetUploadFailLog`, body);
    return res;
  },
  async GetMarketProductUrl(body){
    const res = await JwtAxios.post(`/Market/SearchProductUrl`, body);
    return res;
  }
};
