<template>
  <main id="app" class="vidos_main_wrap">
    <Sidebar />
    <Header />
    <div class="vidos_main_container">
      <div>
        <router-view v-if="templateStore.isCalled" />
      </div>
      <Footer />
    </div>
    <GoTop />
  </main>
</template>
<script>
import Sidebar from '@/components/layout/app/partials/Sidebar';
import Header from '@/components/layout/app/partials/Header';
import Footer from '@/components/layout/app/partials/Footer';
import GoTop from '@/components/layout/app/components/GoTop';

export default {
  components: {
    Header,
    Sidebar,
    Footer,
    GoTop
  },
  async created() {
    if (this.authStore.user === null) {
      await this.authStore.setupUser();
    }
    this.ws_connect();
    if (!this.templateStore.isCalled) {
      this.templateStore.setTemplateList();
    }
  },
  watch: {
    'authStore.user'(user) {
      if (user !== null) {
        this.ws_connect();
      }
    },
    'socketStore.type'(type) {
      if (type !== '') {
        this.socketStore.setStatus(type);
        this.socketStore.setProgress('start');
      }
    }
  }
};
</script>
<style lang="scss">
main.vidos_main_wrap {
  width: 100vw;
  height: 100vh;
  display: flex;

  .vidos_main_container {
    width: calc(100% - 240px);
    margin-top: 60px;
    margin-left: 240px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: $background;
  }
}
</style>
